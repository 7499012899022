import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Row, Col, Container, Form, Button, Table, Modal, Spinner } from 'react-bootstrap';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import { FaTrash, FaKey } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <span>
            Search: {' '}
            <input
                value={globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Type to search..."
                style={{ fontSize: '12px', marginBottom: '10px' }}
            />
        </span>
    );
};

const CreateHboUser = ({ username, area_flag, area_name, region, branch, cluster_name }) => {
    const [namaHbo, setNamaHbo] = useState(''); // State untuk namaTip
    const [noHP, setNoHP] = useState(''); // State untuk noHP

    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [refreshData, setRefreshData] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_SERVER}/data-user-hbo`, {
            params: { username }
        })
        .then(response => {
            setUsers(response.data);
            setLoading(false);
        })
        .catch(error => {
            console.error('There was an error fetching the users!', error);
            setLoading(false);
        });
    }, [username, refreshData]);

    const handleDelete = useCallback((id) => {
        Swal.fire({
            title: 'Peringatan Penghapusan',
            text: "Anda yakin akan menghapus data?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Tentu'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_SERVER}/deleteUserHbo`, {
                    params: { id }
                })
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire('Deleted!', 'The data has been deleted.', 'success');
                            setRefreshData(!refreshData);
                        } else {
                            Swal.fire('Error', response.data.message, 'error');
                        }
                    })
                    .catch(error => {
                        console.error('There was an error deleting the data!', error);
                        Swal.fire('Error', 'There was an error deleting the data!', 'error');
                    });
            }
        });
    }, [refreshData]);

    const handleResetPassword = useCallback((id) => {
        Swal.fire({
            title: 'Peringatan Update',
            text: "Anda yakin akan melakukan reset password user?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Update'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${process.env.REACT_APP_SERVER}/resetPassHbo`, { id })
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire('Success!', 'The password has been updated.', 'success');
                            setRefreshData(!refreshData);
                            setShowModal(false);
    
                        } else {
                            Swal.fire('Error', response.data.message, 'error');
                        }
                    })
                    .catch(error => {
                        console.error('There was an error resetting password!', error);
                        Swal.fire('Error', 'There was an error resetting password!', 'error');
                    });
            }
        });
    }, [refreshData]);

    const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);
    const columns = React.useMemo(
        () => [
            { Header: 'NO', accessor: 'index', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'ID', accessor: 'id_user_hbo', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },            
            { Header: 'PHONE', accessor: 'username', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'NAMA LENGKAP', accessor: 'nama_lengkap', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'AREA', accessor: 'area_flag', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'AREA NAME', accessor: 'area_name', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'REGIONAL', accessor: 'regional', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'BRANCH', accessor: 'branch', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'CLUSTER', accessor: 'cluster_name', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'CREATED AT', accessor: 'tgl_create', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: '#', Cell: ({ row: { original } }) => (
                <div style={{ textAlign: 'center', cursor:'pointer' }}>
                    <FaTrash onClick={() => handleDelete(original.id_user_hbo)} />
                </div>          
            )},
            { Header: '##', Cell: ({ row: { original } }) => (
                <div style={{ textAlign: 'center', cursor:'pointer' }}>
                    <FaKey onClick={() => handleResetPassword(original.id_user_hbo)} />
                </div>          
            )}
        ],
        [handleDelete, handleResetPassword]        
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { pageIndex, pageSize, globalFilter },
        setPageSize,
        setGlobalFilter, // Tambahkan setGlobalFilter
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters,
        useGlobalFilter, // Tambahkan useGlobalFilter
        useSortBy,
        usePagination
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = { 
            namaHbo, 
            username,
            noHP,
            area_flag, 
            area_name, 
            region, 
            branch,
            cluster_name
        };

        axios.post(`${process.env.REACT_APP_SERVER}/insertNewUserHbo`, data)
        .then(response => {
            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'New User Created Successfully',
                    showConfirmButton: true,
                    timer: 1500
                });
                setNamaHbo('');
                setNoHP('');
                setShowModal(false);
                setRefreshData(!refreshData);
            } else {
                //alert('Error: ' + response.data.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message,
                });
            }
        })
        .catch(error => {
            if (error.response && error.response.status === 409) {
                //alert('User dengan nomor handphone ini sudah ada.');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "User dengan nomor handphone ini sudah ada.",
                });
            } else {
                //console.error('There was an error creating the TIP user!', error);
                //alert('Terjadi kesalahan saat membuat user TIP. Silakan coba lagi.');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "Terjadi kesalahan saat membuat user. Silakan coba lagi.",
                });
            }
        });
    };

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}>
            <header className="mb-4 d-flex justify-content-between">
                <h5 className="text-danger">Add New HBO User</h5>
                <Button variant="danger" onClick={() => setShowModal(true)} style={{ fontSize: '12px'}}>Add New</Button>
            </header>
            <Container>                
                <Row>
                    <Col>
                        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                <Spinner animation="border" variant="danger" />
                            </div>
                        ) : (
                            <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '10px' }}>
                                <thead className="custom-thead text-center">
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    className={`${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''} text-center`}
                                                >
                                                    {column.render('Header')}
                                                    <span className="sort-arrow"></span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()} className="p-1">{cell.render('Cell')}</td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>

                <Row className="d-flex align-items-center">
                    <Col xs={8} className="d-flex align-items-center">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<<'}</button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<'}</button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'>'}</button>
                        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-danger" style={{ fontSize: '12px' }}>{'>>'}</button>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center justify-content-end" style={{ fontSize: '12px' }}>
                        <span>Page {pageIndex + 1} of {pageOptions.length}</span>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))} className="form-select ms-2" style={{ fontSize: '12px' }}>
                            {[5, 10, 20].map(size => (
                                <option key={size} value={size}>Show {size}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Container>  

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '15px'}}>ADD NEW PROMOTOR (TIP)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                        <Form onSubmit={handleSubmit}>
                            <Row className='pb-2'>
                                <Col xs={12} md={6} className="px-2 mb-2">
                                    <span style={{ fontSize: '12px' }}>NAMA LENGKAP HBO <span className="text-danger"><b>*</b></span></span>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="namaHbo"
                                        placeholder=""
                                        style={{ width: '90%' }}
                                        value={namaHbo} 
                                        onChange={(e) => setNamaHbo(e.target.value)}
                                        required
                                    />
                                </Col>
                            
                                <Col xs={12} md={6} className="px-2 mb-2 position-relative">
                                    <span style={{ fontSize: '12px' }}>NOMOR HANDPHONE <span className="text-danger"><b>*</b></span></span>
                                    <Form.Control
                                        size="sm"
                                        type="number"
                                        name="noHP"
                                        placeholder=""
                                        style={{ width: '90%' }}
                                        value={noHP} 
                                        onChange={(e) => setNoHP(e.target.value)}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="pb-2 text-end px-4">
                                <Col xs={12} md={12} className="px-2 mb-2">
                                    <Button type="submit" className="bg-warning" style={{ border: '0px solid black' }}>Add New User</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CreateHboUser;
