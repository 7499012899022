import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Container, Table, Spinner } from 'react-bootstrap';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <span>
            Search: {' '}
            <input
                value={globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Type to search..."
                style={{ fontSize: '12px', marginBottom: '10px' }}
            />
        </span>
    );
};

const HomepageReportCheckinDjp = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        setLoading(true);        
        axios.get(`${process.env.REACT_APP_SERVER}/data-report-checkin-djp-external`)
            .then(response => {
                setUsers(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
                setLoading(false);
            });
    }, []);

    const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);
    const columns = React.useMemo(
        () => [
            { Header: () => <div style={{ textAlign: 'center' }}>LIST <br /> NO</div>, accessor: 'index', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>USER <br /> LOGIN</div>, accessor: 'username', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>NAMA <br /> LENGKAP</div>, accessor: 'nama_lengkap', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>AREA <br /> FLAG</div>, accessor: 'area_flag', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>AREA <br /> NAME</div>, accessor: 'area_name', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>REGIONAL <br /> NAME</div>, accessor: 'regional', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>BRANCH <br /> NAME</div>, accessor: 'branch', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>CLUSTER <br /> NAME</div>, accessor: 'cluster_name', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>FLAG <br /> USER</div>, accessor: 'flag', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>CREATED <br /> BY</div>, accessor: 'created_by', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>MTD <br /> DJP</div>, accessor: 'outlet_djp', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>MTD <br /> CHECKIN</div>, accessor: 'total_checkin', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>% <br /> ACH</div>, accessor: 'ach', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{`${value}%`}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>VISIT <br /> BUKA</div>, accessor: 'success_visit', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
        ],[]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { pageIndex, pageSize, globalFilter },
        setPageSize,
        setGlobalFilter, // Tambahkan setGlobalFilter
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters,
        useGlobalFilter, // Tambahkan useGlobalFilter
        useSortBy,
        usePagination
    );

    return (
        <div>
            {/* Table Container */}
            <Container fluid className="my-1">
                <Row>
                    <Col>
                        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                <Spinner animation="border" variant="danger" />
                            </div>
                        ) : (
                            <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '11px', width: '100%' }} width="100%">
                                <thead className="custom-thead text-center">
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    className={`${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''} text-center`}
                                                >
                                                    {column.render('Header')}
                                                    <span className="sort-arrow"></span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()} className="py-1 my-0">{cell.render('Cell')}</td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>

                <Row className="d-flex align-items-center">
                    <Col xs={8} className="d-flex align-items-center">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<<'}</button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<'}</button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'>'}</button>
                        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-danger" style={{ fontSize: '12px' }}>{'>>'}</button>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center justify-content-end" style={{ fontSize: '12px' }}>
                        <span>Page {pageIndex + 1} of {pageOptions.length}</span>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))} className="form-select ms-2" style={{ fontSize: '12px' }}>
                            {[5, 10, 20].map(size => (
                                <option key={size} value={size}>Show {size}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Container>            
        </div>
    );
};

export default HomepageReportCheckinDjp;
