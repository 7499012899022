import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Row, Col, Container, Form, Button, Table, Modal, Spinner } from 'react-bootstrap';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import { FaTrash, FaKey, FaPencilAlt } from "react-icons/fa";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const CreateUserInternalArea = ({ username }) => {
    const [nikUser, setNikUser] = useState('');
    const [namaKaryawan, setNamaKaryawan] = useState('');
    const [areaFlag, setAreaFlag] = useState('');
    const [areaName, setAreaName] = useState('');

    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [refreshData, setRefreshData] = useState(false);
    
    const levelUser = "1";
    const createdFlag = "0";
    const regionalName = null;
    const branchName = null;
    const clusterName = null;

    useEffect(() => {
        const fetchAreaData = async () => {
            try {
                const response = await fetch('/data/territoryData.json');
                const areaneData = await response.json();
                const areaData = areaneData[areaFlag] || areaneData['default'];
                setAreaName(areaData.name);
            } catch (error) {
                console.error('Error fetching area data:', error);
            }
        };
    
        fetchAreaData();
    }, [areaFlag]);

    useEffect(() => {
        setLoading(true);
        
        axios.get(`${process.env.REACT_APP_SERVER}/data-user-int`, {
            params: { id_level : "1" }
        })
            .then(response => {
                setUsers(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
                setLoading(false);
            });
    }, [username, refreshData]);


    const handleDelete = useCallback((id) => {
        Swal.fire({
            title: 'Peringatan Penghapusan',
            text: "Anda yakin akan menghapus data?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Tentu'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_SERVER}/deleteUserInternal`, {
                    params: { id }
                })
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire('Deleted!', 'The data has been deleted.', 'success');
                            setRefreshData(!refreshData);
                            setShowModal(false);

                        } else {
                            Swal.fire('Error', response.data.message, 'error');
                        }
                    })
                    .catch(error => {
                        console.error('There was an error deleting the data!', error);
                        Swal.fire('Error', 'There was an error deleting the data!', 'error');
                    });
            }
        });
    }, [refreshData]);

    const handleUpdate = useCallback((id) => {
        Swal.fire({
            title: 'Peringatan Update',
            text: "Anda akan mengubah role assignment?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Update'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${process.env.REACT_APP_SERVER}/updateUserInternal`, { id })
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire('Updated!', 'The data has been updated.', 'success');
                            setRefreshData(!refreshData);
                            setShowModal(false);
    
                        } else {
                            Swal.fire('Error', response.data.message, 'error');
                        }
                    })
                    .catch(error => {
                        console.error('There was an error updating the data!', error);
                        Swal.fire('Error', 'There was an error updating the data!', 'error');
                    });
            }
        });
    }, [refreshData]);

    const handleResetPassword = useCallback((id) => {
        Swal.fire({
            title: 'Peringatan Update',
            text: "Anda yakin akan melakukan reset password?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Update'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${process.env.REACT_APP_SERVER}/resetPassUserInternal`, { id })
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire('Success!', 'The password has been updated.', 'success');
                            setRefreshData(!refreshData);
                            setShowModal(false);
    
                        } else {
                            Swal.fire('Error', response.data.message, 'error');
                        }
                    })
                    .catch(error => {
                        console.error('There was an error resetting password!', error);
                        Swal.fire('Error', 'There was an error resetting password!', 'error');
                    });
            }
        });
    }, [refreshData]);

    const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);
    const columns = React.useMemo(
        () => [
            { Header: 'NO', accessor: 'index', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'ID', accessor: 'id_user_organik', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'NIK', accessor: 'usernameInt', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'NAMA', accessor: 'namaLengkap', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'AREA', accessor: 'area_flag', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'AREA NAME', accessor: 'area_name', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },            
            { Header: 'RULE', accessor: 'created_flag', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: '#', Cell: ({ row: { original } }) => (
                <div style={{ textAlign: 'center', cursor:'pointer' }}>
                    <FaTrash onClick={() => handleDelete(original.id_user_organik)} />
                </div>          
            )},
            { Header: '*', Cell: ({ row: { original } }) => (
                <div style={{ textAlign: 'center', cursor:'pointer' }}>
                    <FaPencilAlt onClick={() => handleUpdate(original.id_user_organik)} />
                </div>          
            )},
            { Header: ' ', Cell: ({ row: { original } }) => (
                <div style={{ textAlign: 'center', cursor:'pointer' }}>
                    <FaKey onClick={() => handleResetPassword(original.id_user_organik)} />
                </div>          
            )}
        ],
        [handleDelete, handleUpdate, handleResetPassword]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { pageIndex, pageSize },
        setPageSize,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );    

    const handleSubmit = (e) => {
        e.preventDefault(); 

        const data = { username, namaKaryawan, nikUser, levelUser, createdFlag, areaFlag, areaName, regionalName, branchName, clusterName }; 

        axios.post(`${process.env.REACT_APP_SERVER}/insertUserAdmin`, data)
        .then(response => {
            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Insert Data Sukses',
                    showConfirmButton: true,
                    timer: 1500
                });
                setNamaKaryawan('');
                setNikUser(''); 
                setRefreshData(!refreshData);
                setShowModal(false);
                
            } else {
                alert('Error: ' + response.data.message);
            }
        })
        .catch(error => {
            if (error.response && error.response.status === 409) {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal!',
                    text: 'User already exists.',
                    confirmButtonText: 'Kembali',
                    confirmButtonColor: '#d33'
                });
            } else {
                //console.error('There was an error creating the TIP user!', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: 'Terjadi kesalahan saat membuat user. Silakan coba lagi.',
                    confirmButtonText: 'Kembali',
                    confirmButtonColor: '#d33'
                });
            }
        });
    };

    return (
        <div style={{ height: '70vh', overflowY: 'auto' }}>
            <header className="mb-4 d-flex justify-content-between">
                <h5 className="text-danger">Insert Data Users - Area Level</h5>
                <Button variant="danger" onClick={() => setShowModal(true)} style={{ fontSize: '12px' }}>Add New</Button> {/* Add New Button */}
            </header>

            <Container>
                <Row>
                    <Col>
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                <Spinner animation="border" variant="danger" />
                            </div>
                        ) : (
                            <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '10px' }}>
                                <thead className="custom-thead text-center">
                                    {headerGroups.map((headerGroup, index) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    className={`${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''} text-center`}
                                                >
                                                    {column.render('Header')}
                                                    <span className="sort-arrow"></span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()} className="py-1 my-0">{cell.render('Cell')}</td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>

                <Row className="d-flex align-items-center">
                    <Col xs={8} className="d-flex align-items-center">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<<'}</button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<'}</button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'>'}</button>
                        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-danger" style={{ fontSize: '12px' }}>{'>>'}</button>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center justify-content-end" style={{ fontSize: '12px' }}>
                        <span>Page {pageIndex + 1} of {pageOptions.length}</span>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))} className="form-select ms-2" style={{ fontSize: '12px' }}>
                            {[5, 10, 20].map(size => (
                                <option key={size} value={size}>Show {size}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Container>

            {/* Modal for Creating Personal DJP */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '15px' }}>CREATE USER HQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                            <Row className='pb-2'>
                                <Col xs={12} md={4} className="px-2 mb-2">
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="namaKaryawan"
                                        value={namaKaryawan}
                                        onChange={(e) => setNamaKaryawan(e.target.value)}
                                        placeholder="Nama Karyawan"
                                        style={{ width: '90%' }}
                                        required
                                    />
                                </Col>
                                <Col xs={12} md={2} className="p-0 mb-2">
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="nikUser"
                                        value={nikUser}
                                        onChange={(e) => setNikUser(e.target.value)}
                                        placeholder="NIK"
                                        style={{ width: '90%' }}
                                        required
                                    />
                                </Col>
                                <Col xs={12} md={3} className="px-2 mb-2">
                                    <Form.Select
                                        size="sm"
                                        name="areaFlag"
                                        value={areaFlag}
                                        onChange={(e) => setAreaFlag(e.target.value)}
                                        required
                                        style={{ width: '90%' }}
                                    >
                                        <option value="" disabled>Pilih Area</option>
                                        <option value="AREA 1">AREA 1</option>
                                        <option value="AREA 2">AREA 2</option>
                                        <option value="AREA 3">AREA 3</option>
                                        <option value="AREA 4">AREA 4</option>
                                    </Form.Select>
                                </Col>
                                <Col xs={12} md={3} className="px-2 mb-2">
                                    <Button type="submit" className="bg-warning" style={{ border: '0px solid black', fontSize: '12px' }}>SUBMIT USER</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
            </Modal>            
        </div>
    );
};

export default CreateUserInternalArea;
