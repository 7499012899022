import React from 'react';
import { FaHandshake, FaHome, FaUser, FaPaperPlane } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const Sidebar = ({ isOpen, level, created_flag }) => {
    const navigate = useNavigate();

    const routesHome = () => {
        navigate(`/`);
    };

    /*
    const routesFormPengajuan = () => {
        navigate(`/form-pengajuan`);
    };
    */

    const routesCreateUserTip = () => {
        navigate(`/createUserTip`);
    };
    const routesCreateUserHbo = () => {
        navigate(`/createUserHbo`);
    };
    const routesCreateUserInternal = () => {
        navigate(`/createUserInternal`);
    };

    const routesPersonalDjp = () => {
        navigate(`/personalDjp`);
    };

    const routesTipDjp = () => {
        navigate(`/tipDjp`);
    };

    const routesHboDjp = () => {
        navigate(`/hboDjp`);
    };

    /*
    const routesReportDjpExternal = () => {
        navigate(`/report-external-djp`);
    };
    */

    const handleLogout = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to log out?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log out!',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER}/logout`, {
                    method: 'POST',
                    credentials: 'include',
                });

                const data = await response.json();
                if (data.loggedIn === false) {
                    sessionStorage.clear();
                    window.location.href = `${process.env.REACT_APP_FRONTEND}`;
                }
            } catch (error) {
                console.error('Error logging out:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Logout Failed',
                    text: 'An error occurred while trying to log out.',
                });
            }
        }
    };

    return (
        <nav id="sidebar" className={`js-sidebar ${isOpen ? 'open' : 'closed'}`} style={{
            width: isOpen ? '250px' : '0',
            height: '100vh',
            color: '#fff',
            position: 'fixed',
            top: 0,
            left: 0,
            overflowX: 'hidden',
            transition: 'width 0.3s ease',
            boxShadow: '3px 0 10px rgba(0, 0, 0, 0.3)',
            backgroundColor: '#343a40',
            zIndex: 100
        }}>
            <div className="sidebar-content js-simplebar">
                <div className="sidebar-brand d-flex">
                    <div className="p-1 text-center" style={{ borderRadius: '50%', width: '40px', backgroundColor: 'red' }}>
                        <FaHandshake className="mt-0" />
                    </div>
                    <span className="mx-2 align-middle" style={{ fontSize: '25px' }}>FANNY</span>
                </div>

                <ul className="sidebar-nav">
                    <li className="sidebar-item active">
                        <div className="sidebar-link" onClick={() => routesHome()}>
                            <FaHome />
                            <span className="align-middle">Home</span>
                        </div>
                    </li>
                    
                    {created_flag === "1" && (level === "0" || level === "1" || level === "2" || level === "5") ? (
                        <>
                            <li className="sidebar-header">USER MANAGEMENT</li>
                            <li className="sidebar-item mb-0">
                                <div className="sidebar-link py-1" onClick={() => routesCreateUserInternal()}>
                                    <FaUser />
                                    <span className="align-middle">Create User Internal</span>
                                </div>
                            </li>
                        </>
                    ) : null}
                    {created_flag === "1" && level === "3" ? (
                        <li className="sidebar-item mb-0">
                            <div className="sidebar-link py-1" onClick={() => routesCreateUserTip()}>
                                <FaUser />
                                <span className="align-middle">Add TIP User</span>
                            </div>
                        </li>
                    ) : null}
                    {created_flag === "1" && level === "4" ? (
                        <li className="sidebar-item mb-0">
                            <div className="sidebar-link py-1" onClick={() => routesCreateUserHbo()}>
                                <FaUser />
                                <span className="align-middle">Add HBO User</span>
                            </div>
                        </li>
                    ) : null}



                    <li className="sidebar-header">DJP SCHEDULE</li>
                    <li className="sidebar-item mb-0">
                        <div className="sidebar-link py-1" onClick={() => routesPersonalDjp()}>
                            <FaUser />
                            <span className="align-middle">Personal</span>
                        </div>
                    </li>
                    {created_flag === "1" && level === "3" ? (
                        <li className="sidebar-item">
                            <div className="sidebar-link py-1" onClick={() => routesTipDjp()}>
                                <FaPaperPlane />
                                <span className="align-middle">Promotor (TIP)</span>
                            </div>
                        </li>
                    ) : null}
                    {created_flag === "1" && level === "4" ? (
                        <li className="sidebar-item">
                            <div className="sidebar-link py-1" onClick={() => routesHboDjp()}>
                                <FaPaperPlane />
                                <span className="align-middle">Buddies (HBO)</span>
                            </div>
                        </li>
                    ) : null}

                    { /*
                    <li className="sidebar-header">REPORTING</li>
                    <li className="sidebar-item mb-0">
                        <div className="sidebar-link py-1" onClick={() => routesReportDjpExternal()}>
                            <FaBook />
                            <span className="align-middle">DJP External</span>
                        </div>
                    </li>
                    */}
                </ul>
                <div className="sidebar-cta">
                    <div className="sidebar-cta-content">
                        <div className="d-grid text-center" style={{ fontSize: '13px' }}>
                            <Button onClick={handleLogout} className="btn btn-danger">Logout</Button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Sidebar;
