import React, { useState } from 'react';
import { FaBars, FaUser, FaKey, FaServicestack, FaSignOutAlt } from "react-icons/fa";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const Header = ({ toggleSidebar, username }) => {
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const handleLogout = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to log out?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log out!',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER}/logout`, {
                    method: 'POST',
                    credentials: 'include',
                });

                const data = await response.json();
                if (data.loggedIn === false) {
                    sessionStorage.clear();
                    window.location.href = `${process.env.REACT_APP_FRONTEND}`;
                }
            } catch (error) {
                console.error('Error logging out:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Logout Failed',
                    text: 'An error occurred while trying to log out.',
                });
            }
        }
    };

    const handleShowChangePasswordModal = () => setShowChangePasswordModal(true);
    const handleCloseChangePasswordModal = () => setShowChangePasswordModal(false);

    const handleSubmitChangePassword = async () => {
        if (newPassword !== confirmNewPassword) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'New passwords do not match!',
            });
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/changePassInternal`, {
                username,
                oldPassword,
                newPassword
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Password Changed',
                    text: 'Your password has been changed successfully.',
                });
                handleCloseChangePasswordModal();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message || 'Failed to change the password.',
                });
            }
        } catch (error) {
            console.error('Error changing password:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while trying to change the password.',
            });
        }
    };

    return (
        <nav className="px-3 navbar navbar-expand navbar-light navbar-bg">
            <span className="sidebar-toggle" onClick={toggleSidebar}>
                <FaBars size={20} />
            </span>

            <h4 className="text-danger"> <b>Panel Dashboard</b></h4>
            
            <div className="navbar-collapse collapse mx-3">
                <ul className="navbar-nav navbar-align">
                    <li className="nav-item dropdown">
                        <div className="nav-link d-none d-sm-inline-block" style={{ cursor: 'pointer' }} data-bs-toggle="dropdown">
                            <FaUser size={15} /> {username}
                        </div>

                        <div className="dropdown-menu dropdown-menu-end">
                            <div className="dropdown-item" onClick={handleShowChangePasswordModal} style={{cursor: 'pointer'}}>
                                <FaKey size={15} /> &nbsp;
                                Change Password
                            </div>
                            <div className="dropdown-divider"></div>

                            <div className="dropdown-item">
                                <FaServicestack size={15}/> &nbsp;
                                Help Center
                            </div>
                            <div className="dropdown-divider"></div>
                            <div className="dropdown-item" onClick={handleLogout} style={{cursor: 'pointer'}}>
                                <FaSignOutAlt size={15} /> &nbsp;
                                Log out
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <Modal show={showChangePasswordModal} onHide={handleCloseChangePasswordModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formOldPassword" className="mb-4">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                placeholder="Enter old password"
                            />
                        </Form.Group>
                        <Form.Group controlId="formNewPassword" className="mb-4">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Enter new password"
                            />
                        </Form.Group>
                        <Form.Group controlId="formConfirmNewPassword" className="mb-4">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                placeholder="Confirm new password"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseChangePasswordModal}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleSubmitChangePassword}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </nav>
    );
};

export default Header;
