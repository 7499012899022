import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import './css/Login.css';
import img2 from './images/login_icon.png';

function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem("username");
        if (isLoggedIn) {
            navigate("/");
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();
            //console.log(data); // Log the response data for debugging

            if (data.success) {
                sessionStorage.setItem("username", data.username);
                sessionStorage.setItem("nama_lengkap", data.nama_lengkap);
                sessionStorage.setItem("area_flag", data.area_flag);
                sessionStorage.setItem("area_name", data.area_name);
                sessionStorage.setItem("regional", data.regional);
                sessionStorage.setItem("branch", data.branch);
                sessionStorage.setItem("cluster_name", data.cluster_name);
                sessionStorage.setItem("level", data.level);
                sessionStorage.setItem("flag_user", data.flag_user);
                sessionStorage.setItem("created_flag", data.created_flag);
                window.location.href = `${process.env.REACT_APP_FRONTEND}`;
            } else {
                setError(data.message || "Invalid credentials");
            }
        } catch (error) {
            console.error("Terjadi kesalahan:", error);
            setError("An error occurred. Please try again.");
        }
    };    

    return (
        <Container className="vh-100 d-flex justify-content-center align-items-center">
            <Row style={{ display: "flex", alignItems: "center" }}>
                <Col xs={12} md={7} className="text-center justify-content-center p-100" style={{ padding: "30px" }}>
                    <img src={img2} alt="login-logo" style={{ width: "80%", height: "auto", paddingTop: "50px" }}/>
                </Col>
                <Col xs={12} md={5} className="justify-content-top" style={{ padding: "30px" }}>                    
                    {error && (
                        <Alert variant="danger" style={{ padding: "10px", fontWeight: "bold" }}>
                            {error}
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><span style={{ fontWeight: "bold", color: "#999" }}>Username</span></Form.Label>
                            <Form.Control required type="text" name="username" placeholder="input username" onChange={(e) => setUsername(e.target.value)} style={{ padding: "12px", fontSize: "18px" }}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label><span style={{ fontWeight: "bold", color: "#999" }}>Password</span></Form.Label>
                            <Form.Control type="password" name="password" required placeholder="******" onChange={(e) => setPassword(e.target.value)} style={{ padding: "12px", fontSize: "18px" }}/>
                        </Form.Group>

                        <Button type="submit" className="custom-button mt-2">Login</Button> 
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default LoginPage;
