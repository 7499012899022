import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Fade from 'react-bootstrap/Fade'; // Import Fade component
import { CSSTransition, TransitionGroup } from 'react-transition-group'; // Import CSSTransition and TransitionGroup
import './css/animations.css'; // Import custom animations

//import FormCreateUserAdmin from './FormCreateUserAdmin';
import CreateUserInternalArea from './CreateUserInternalArea';
import CreateUserInternalRegional from './CreateUserInternalRegional';
import CreateUserInternalBranch from './CreateUserInternalBranch';
import CreateUserInternalCluster from './CreateUserInternalCluster';
import CreateUserInternalPusat from './CreateUserInternalPusat';

const UserManagement = ({ username, level }) => {
    const [activeSection, setActiveSection] = useState('0');
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true); // Start animation when component is mounted
    }, []);

    const renderSection = () => {
        switch (activeSection) {
            case '0': return (<CreateUserInternalArea username={username} />);
            case '1': return (<CreateUserInternalRegional username={username} />);
            case '2': return (<CreateUserInternalBranch username={username} />);
            case '3': return (<CreateUserInternalCluster username={username} />);
            case '4': return (<CreateUserInternalPusat username={username} />);
            default: return (<CreateUserInternalPusat username={username} />);
        }
    };

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF' }}>
            <Fade in={show} timeout={300}>
                <header className={`mb-4 ${show ? 'slide-up-enter-active' : 'slide-up-enter'}`}>
                    <h5>User Management</h5>
                    Halaman untuk menambahkan user terbaru
                </header>
            </Fade>

            <Container>
                <Row>
                    <Col xs={12} md={3} className="pt-3">
                        <TransitionGroup>
                            <CSSTransition in={show} timeout={300} classNames="slide-up" unmountOnExit>
                                <ListGroup variant="flush">
                                    {level === "0" ? (
                                        <>
                                            <ListGroup.Item action onClick={() => setActiveSection('4')} className="rounded" style={{ backgroundColor: activeSection === '4' ? 'red' : 'transparent', color: activeSection === '4' ? 'white' : 'black',}}>
                                                User Headquarter
                                            </ListGroup.Item>
                                        </>
                                    ) : null}

                                    {level === "0" || level === "5" ? (
                                        <>
                                            <ListGroup.Item action onClick={() => setActiveSection('0')} className="rounded" style={{ backgroundColor: activeSection === '0' ? 'red' : 'transparent', color: activeSection === '0' ? 'white' : 'black',}}>
                                                User Area
                                            </ListGroup.Item>
                                        </>
                                    ) : null}    
                                    {level === "0" || level === "1" || level === "5" ? (
                                        <>
                                            <ListGroup.Item action onClick={() => setActiveSection('1')} className="rounded" style={{ backgroundColor: activeSection === '1' ? 'red' : 'transparent', color: activeSection === '1' ? 'white' : 'black',}}>
                                                User Regional
                                            </ListGroup.Item>
                                        </>
                                    ) : null}

                                    {level === "0" || level === "1" || level === "2" || level === "5" ? (
                                        <>
                                            <ListGroup.Item action onClick={() => setActiveSection('2')} className="rounded" style={{ backgroundColor: activeSection === '2' ? 'red' : 'transparent', color: activeSection === '2' ? 'white' : 'black',}}>
                                                User Branch
                                            </ListGroup.Item>
                                            <ListGroup.Item action onClick={() => setActiveSection('3')} className="rounded" style={{ backgroundColor: activeSection === '3' ? 'red' : 'transparent', color: activeSection === '3' ? 'white' : 'black',}}>
                                                User Cluster
                                            </ListGroup.Item>
                                        </>
                                    ) : null}
                                </ListGroup>
                            </CSSTransition>
                        </TransitionGroup>
                    </Col>
                    <Col xs={12} md={9} className="p-4">
                        <Fade in={show} timeout={300}>
                            <div className="slide-up-enter-active">
                                {renderSection()}
                            </div>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default UserManagement;
