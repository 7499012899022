import React from 'react';

const Footer = () => {
  return (
    <footer className="py-1 bg-light mt-auto">
      <div className="container-fluid px-4">
        <div className="d-flex align-items-center justify-content-between small">
          <div className="text-muted"></div>
          <div>Copyright Fanny.id &copy; 2024 - Supported by Reporting & Analytics Dashboard Management</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
