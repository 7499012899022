import React from 'react';
import Alert from 'react-bootstrap/Alert';
import HomepageReportTip from './HomepageReportTip';
import HomepageCardHeader from './HomepageCardHeader';
import DownloadRawExternal from './DownloadRawExternal';
import HomepageReportCheckinDjp from './HomepageReportCheckinDjp';

const Homepage = ({ namalengkap, level, areaflag, regional, branch }) => {

  let userRole, ket1, ket2;
  switch (level) {
    case '0':
      userRole = 'Super Admin';
      ket1 = '';
      ket2 = 'all';
      break;
    case '5':
      userRole = 'HQ';
      ket1 = '';
      ket2 = 'all';
      break;
    case '1':
      userRole = 'Area';
      ket1 = 'dapat menambahkan user Regional, Branch dan Cluster';
      ket2 = `all_${areaflag.toLowerCase()}`;
      break;
    case '2':
      userRole = 'Regional';
      ket1 = 'dapat menambahkan user Branch dan Cluster';
      ket2 = `region_${regional.toLowerCase()}`;
      break;
    case '3':
      userRole = 'Branch';
      ket1 = 'dapat menambahkan user dan membuat DJP khusus untuk User TIP';
      ket2 = `branch_${branch.toLowerCase()}`;
      break;
    case '4':
      userRole = 'Cluster';
      ket1 = 'dapat menambahkan user dan membuat DJP khusus untuk User HBO';
      ket2 = `branch_${branch.toLowerCase()}`;
      break;
    default:
      userRole = 'Unknown';
      ket1 = '';
      ket2 = '';
      break;
  }

  return (
    <main className="content m-0 p-0 vh-100" style={{ overflowY: 'auto' }}>
      { /*<div className="m-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}> */}


      <div className="row mx-4 p-3">
        <Alert key="primary" variant="primary" className="mb-0">
          Semangat pagi kak <strong>{namalengkap}. </strong>
          Status Anda sebagai user <strong>{userRole} </strong> {ket1}
        </Alert>
      </div>

      <div className="row mx-1 px-4">
        <HomepageCardHeader />
      </div>


      <div className="row mx-1 px-4">
        <div className="col-xl-8 col-xxl-8">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title my-2">TIP PERFORMANCE</h5>
            </div>
            <div className="card-body pt-2 pb-3">
              <div className="chart chart-sm">
                <HomepageReportTip />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-xxl-4">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title my-2">DOWNLOAD RAW HBO & TIP</h5>
            </div>
            <div className="card-body pt-2 pb-3">
              <div className="chart chart-sm">
                <DownloadRawExternal level={level} areaflag={areaflag} regional={regional} branch={branch} ket2={ket2}/>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-1 px-4">
        <div className="col-xl-12 col-xxl-12">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title my-2">CHECKIN TO DJP EFFECTIVENESS</h5>
            </div>
            <div className="card-body pt-2 pb-3">
              <div className="chart chart-sm">
                <HomepageReportCheckinDjp />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Homepage;
