import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const DownloadRawExternal = ( {level, areaflag, regional, branch, ket2} ) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState('');

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

    const handleDownloadCheckinRaw = async () => {
        try {        
            let downloadUrl, ketfile;
            switch (selectedOption) {
                case '1':
                    downloadUrl = `${process.env.REACT_APP_SERVER}/downloadDjpCheckinExt`;
                    ketfile = "rawdata_checkin_external";
                    break;
                case '2':
                    downloadUrl = `${process.env.REACT_APP_SERVER}/downloadDjpExt`;
                    ketfile = "rawdata_djp_external";
                    break;
                case '3':
                    downloadUrl = `${process.env.REACT_APP_SERVER}/downloadSurveyResultExt`;
                    ketfile = "rawdata_survey_external";
                    break;
                default:
                console.error('No valid option selected');
                return;
            }

            const response = await axios.get(downloadUrl, {
                params: {
                    level,
                    areaflag,
                    regional,
                    branch,
                    startDate: startDate.toISOString().split('T')[0], // Format date to YYYY-MM-DD
                    endDate: endDate.toISOString().split('T')[0]    // Format date to YYYY-MM-DD
                },
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${ketfile}_${ket2}_${formattedStartDate}_${formattedEndDate}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading data:', error);
        }
    };

    return (
        <>
            <div className="d-flex flex-column mt-2 px-1">
                <div className="row mt-3">
                    <div className="col-6">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="yyyy-MM-dd"
                            className="form-control w-100 datepicker-full-width"
                        />
                    </div>

                    <div className="col-6 text-right">
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat="yyyy-MM-dd"
                            className="form-control w-100"
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <select
                            className="form-select"
                            aria-label="Select rawdata type"
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}
                        >
                            <option value="" disabled selected>--- Pilih Opsi Raw Data ---</option>
                            <option value="1">Checkin Outlet</option>
                            <option value="2">DJP Schedule</option>
                            <option value="3">Survey Outlet</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <button onClick={handleDownloadCheckinRaw} className="btn btn-danger w-100">
                            Download Now
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DownloadRawExternal